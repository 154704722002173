<template>
  <div class="student-page"  v-if="person">
     <top-decorations></top-decorations>
      <div class="container">
    <div class="backlink bookmark-container">
      <router-link class="bookmark" to="/students">
        student
      </router-link>
    </div>
    <div class="top-metadata-container">
      <h1 class="page-title">{{ personName(person) }}</h1>
      <div class="linkedin" v-if="person.attributes.field_linkedin_profile">
        <a target="_blank" :href="person.attributes.field_linkedin_profile.uri"
          ><img src="../assets/icon-linkedin.svg" alt=""><span>View Profile</span></a>
      </div>
      <div class="edition" v-if="year && edition">
        <div class="edition-label">{{edition}}' edition</div>
        <div class="edition-year">{{ year }}</div>
      </div>
    </div>
    <div class="projects">
      <div class="project" v-for="(project,index) of projects" :key="index">
        <div class="text">
          <h6 class="light mb-lg-0 section-title">
            PROJECT'S TITLE
          </h6>
          <h1 class="light ">
            {{ project.attributes.title }}
          </h1>
          <div class="supervisor custom-margin" v-if="project.lecturers.length > 0">
            <h6 class="light mb-lg-0" >
              <img src="../assets/icon-course-lecturer-grey.svg" alt=""> Supervisor(s)
            </h6>
            <p>
              {{ project.lecturers.map(l=>l.attributes.title).join(', ') }}
            </p>
          </div>
          <div class="supervisor custom-margin" v-if="project.attributes.field_external_supervisors">
            <h6 class="light mb-lg-0" >
              <img src="../assets/icon-course-lecturer-grey.svg" alt=""> External Supervisor(s)
            </h6>
            <p>
              {{ project.attributes.field_external_supervisors}}
            </p>
          </div>
          <div class="description custom-margin">
            <h6 class="light mb-lg-0">
              Abstract
            </h6>
            <div v-html="project.attributes.field_shared_description.processed"></div>
            
            <div class="partner custom-margin">
              <div v-for="(field_sponsor,index) of project.field_sponsor_ref" :key="index">
                 <h6 class="light mb-lg-0">{{field_sponsor.attributes.field_label}}</h6>
                <p v-for="partner of getProjectPartners(field_sponsor)" :key="partner.id">{{partner.attributes.title}}</p>
              </div>
            </div>
            
            <div class="row d-flex justify-content-center" v-if="project.attachment">
              <a target="_blank" :href="endpoint + project.attachment.attributes.uri.url" class="cta cta-primary text-uppercase">
                go to the thesis
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
import TopDecorations from "@/components/TopDecorations.vue";


export default {
    components: { TopDecorations },
  data() {
    return {
      academicYears: [],
      person: null,
      year:null,
      edition: null,
      projects: [],
      fetchedPartnerUrls: [],
      partners: [],
      endpoint: process.env.VUE_APP_ENDPOINT,
    };
  },
  computed: {
    personNID() {
      return this.$route.params.nid.split('-').slice('-1')[0];
    },
  },
  methods: {
    personName(person) {
      return person.attributes.title;
    },
    fetchPersonData() {
      if (this.personNID) {
        fetch(
          `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/person/?filter[drupal_internal__nid]=${this.personNID}`
        )
          .then((res) => {
            return res.json();
          })
          .then((response) => {
            this.person = response.data[0];
            this.fetchProjects(
              this.person.id
            );
            this.fetchAcademicYears(
              this.person.relationships.field_academic_year.data
            );
          });
      }
    },
    fetchAcademicYears(academicYears) {
      if (academicYears.length > 0) {
        academicYears.forEach((year) => {
          fetch(
            `${process.env.VUE_APP_ENDPOINT}/jsonapi/taxonomy_term/academic_year/${year.id}`
          )
            .then((res) => res.json())
            .then((response) => {
              this.academicYears.push(response.data);
              this.year = response.data.attributes.name;
              const firstYear = 2014;
              this.edition = parseInt(this.year.split("/")[0]) + 1 - firstYear;
            });
        });
      }
    },
    fetchPartner(partner_url){
      if(!this.fetchedPartnerUrls.includes(partner_url)){
        this.fetchedPartnerUrls.push(partner_url);
        fetch(partner_url)
          .then(res => res.json())
          .then((json) => {
            this.partners.push(json.data)
          })
      }
    },
    getProjectPartners(field_sponsor){
      return this.partners.filter(partner=>{
        return field_sponsor.relationships.field_partner_ref.data.find((partner_ref)=>{
            return partner.id === partner_ref.id
        });
      })
    },
    fetchProjects(personID){
      fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/project?include=field_project_author,field_sponsor_ref,field_shared_attachment,field_course_lecturer&filter[field_project_author.id]=${personID}`)
        .then((res) => res.json())
        .then((response) => {
          let projects = response.data
          let included =  response.included;
          
          if (included) {
            /* aggiungo campo lecturer */
            projects = projects.map((project) => {
              if (project.relationships.field_course_lecturer.data && project.relationships.field_course_lecturer.data.length > 0) {
                var lecturerIDS = project.relationships.field_course_lecturer.data.map(l=>l.id);
                ////console.log(project.relationships.field_course_lecturer,lecturerIDS)
                project.lecturers = included.filter((lecturer) => {
                  return lecturerIDS.includes(lecturer.id);
                });
              } else {
                project.lecturers = [];
              }
              return project;
            });
            /* aggiungo campo sponsor */
            projects = projects.map((project) => {
              project.field_sponsor_ref = project.relationships.field_sponsor_ref.data.map(field_sponsor_ref => {
                return included.find((item) => {
                  return item.id === field_sponsor_ref.id;
                });
              });
              return project;
            });

            /* aggiungo autore */
            projects = projects.map((project) => {
              if (project.relationships.field_project_author.data) {
                project.author = included.find((item) => {
                  return item.id === project.relationships.field_project_author.data.id;
                });
              }
              return project
            })

            /* aggiungo allegati */
            projects = projects.map((project) => {
              if (project.relationships.field_shared_attachment.data) {
                project.attachment = included.find((item) => {
                  return project.relationships.field_shared_attachment.data.find(attachment=>{
                    return attachment.id === item.id
                  });
                });
              }
              return project;
            })

            projects.forEach( project =>{
              
              /* fetch dati dei partner e aggiungo ai partnerIds del progetto */
              project.partnerIds = [];
              project.field_sponsor_ref.forEach((sponsor_ref=>{
                sponsor_ref.relationships.field_partner_ref.data.forEach(partnerData=>{
                  project.partnerIds.push(partnerData.id);
                  this.fetchPartner(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/partner/${partnerData.id}`);
                })
              }))

            });
            this.projects = projects;
            return projects;
          }
        });
    }
  },
  mounted() {
    this.fetchPersonData();
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  img {
    left: -96px;
    position: absolute;
  }
}
.partner {
  &>div{
    display: flex;
    flex-direction: row;
    h6 {
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
    p{
       margin-right: 10px;
    }
  }
}
.bookmark-container {
  position: absolute;
}

.student-page{
  position: relative;
  &:after{
    background-size: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    background:#F9F9F9 ;
    content: "";
    border-radius: 5000px 5000px 0px 0px;
    top: 9rem;
    z-index: -9;
    left: 0;
    right: 0;
  }
}


.text{
  margin: 4rem 8rem 0rem 8rem;
}
button{
  margin:4rem 0rem ;
}
.custom-margin{
  margin:2rem 0rem ;
}
.page-title{
  margin-bottom: 1rem;
  padding-top: 13rem;
}
.top-metadata-container{
  text-align: center;
  position: relative;
}
.top-metadata-container:before{
    background-size: 100%;
    width: 600px;
    height: 390px;
    position: absolute;
    background:transparent ;
    content: "";
    border-radius:0px 0px 5000px 5000px ;
    top: 0rem;
    z-index: -8;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    border-bottom: 1px solid #1dc07e54;
    border-right: 1px solid #1dc07e54;
    border-left: 1px solid #1dc07e54;
}
.edition{
    margin-bottom: 4rem;
}
.edition-label{
  color:#ACACAC;
  text-transform: uppercase;
}
.linkedin {
  margin-bottom: 1rem;  
  color: #1dc07e;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  display: block;
  img {
    margin-right: 10px;
  }
}
/*
.description{
  &::before{
    content: '';
    padding-bottom: 25px;
    border-top: 2px solid #1dc07e;
    width: 78px;
    display: block;
  }
}
*/
@media (min-width:992px) {
  
  /*
.text .section-title{
  position: relative;
  &::before{
    position: absolute;
    right: calc(100% + 20px);
    top: -20px;
    content: '';
    background-image: url(../assets/icon-section.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 44px;
    width: 66px;
  }
}
*/
}
@media (max-width:992px) {
  .text {
    margin: 4rem 0rem 0rem 0rem;
  }
  h1.light, h1 {
    line-height: 2.3rem;
}
  .supervisor h6 {
    margin-bottom: 0;
  }
  button {
    margin: 2rem 0rem;
  }
}
@media (max-width:768px) {
  .title {
  img {
    display: none;
  }
}

  .decorations{
    display: none;
  }
  .bookmark-container {
    height: 149px;
  }
  .page-title {
    margin-bottom: 1rem;
    padding-top: 7rem;
  }
  .student-page:after {
    top: 0rem;
  }
  h1.light, h1 {
    line-height: 2.1rem;
    font-size: 1.8rem;
  }
  .text {
    margin: 3rem 0rem 0rem 0rem;
  }
  .top-metadata-container:before{
    display: none;
  }
  .linkedin span {
    display: none;
  }
}


@media (max-width:576px) {
  .partner {
    &>div{
      display: flex;
      flex-direction: column;
    }
  }
}

</style>